const NODE_ENV = "development";

export const baseUrl =
  NODE_ENV === "development"
    ? // ? "http://3.144.85.173:3800"
      //  "http://127.0.0.1:3800"
      "https://api-es-dev.hipaasafe.app"
    : "https://api-es.hipaasafe.app";



   // export const baseUrl = "http://localhost:3800/" ;

export const wsBaseUrl =
  NODE_ENV === "development"
    ? "wss://api-es-dev.hipaasafe.app"
    : "wss://api-es.hipaasafe.app";

export const cometChatConfig =
  NODE_ENV === "development"
    ? {
      appID: "238125da67fbeead",
      appRegion: "us",
      authKey: "3b1d59b44f626e54c41f9719d3323ec89d7722b4",
    }
    : {
      appID: "194597c4e37ea49f",
      appRegion: "us",
      authKey: "2f1cd984d0e65bce1b5fc511200af3b202567656",
    };

export const oneSignalAppId =
  NODE_ENV === "development"
    ? "da0fdd6b-907b-4149-9485-f48f95038638"
    : "4901045b-9a08-4e0c-9707-c1f9c522a8c5";
// API's
export const loginSendOTP = "/user/login/send-otp";
export const loginVerifyOTP = "/user/login/validate-otp";

// Static URLS
// export const S3BaseUrl =
//   "https://api-es-dev.hipaasafe.app/user/image?id=hs/user/reports/";

export const S3BaseUrl =
NODE_ENV === "development"
  ? "https://api-es-dev.hipaasafe.app/user/image?id=hs/user/reports/"
  : "https://api-es.hipaasafe.app/user/image?id=hs/user/reports/";



// export const S3ImageBaseUrl =
//   "https://api-es-dev.hipaasafe.app/user/image?id=hs/user/reports/";

export const S3ImageBaseUrl =
NODE_ENV === "development"
  ? "https://api-es-dev.hipaasafe.app/user/image?id=hs/user/reports/"
  : "https://api-es.hipaasafe.app/user/image?id=hs/user/reports/";



// export const S3QRCodeBaseUrl =
//   "https://api-es-dev.hipaasafe.app/user/image?id=hs/doctor/qr-code/";

export const S3QRCodeBaseUrl =
NODE_ENV === "development"
  ? "https://api-es-dev.hipaasafe.app/user/image?id=hs/doctor/qr-code/"
  : "https://api-es.hipaasafe.app/user/image?id=hs/doctor/qr-code/";



// export const S3ProfileImage =
//   "https://api-es-dev.hipaasafe.app/user/image?id=hs/avatar/";


export const S3ProfileImage =
NODE_ENV === "development"
  ? "https://api-es-dev.hipaasafe.app/user/image?id=hs/avatar/"
  : "https://api-es.hipaasafe.app/user/image?id=hs/avatar/";


export const StripePriceId = 
NODE_ENV === "development"
  ? "price_1MFBz6Hmv0ACRuxYYDnYcBmI"
  : "price_1JQZ0nJZ6Z4ZQZ4ZQZ4ZQZ4Z";

export const stripeSuccessUrl = 
NODE_ENV === "development"
  ? "https://partner-dev.hipaasafe.app/register/doctor/success?session_id={CHECKOUT_SESSION_ID}"
  : "https://partner.hipaasafe.app/register/doctor/success?session_id={CHECKOUT_SESSION_ID}";


export const stripeCancelUrl =
NODE_ENV === "development"
  ? "https://partner-dev.hipaasafe.app/cancel"
  : "https://partner.hipaasafe.app/cancel";


export const doctorOrgnizationMapSwitch = "/user/doctor/switch/organization";
