
import {loadStripe} from '@stripe/stripe-js';


let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_test_51MBw9vHmv0ACRuxYXazCfIL7et9SS57J9TGzzOtocYcEKh2CinfhvMjXA672tq2NIxRIkXR5avKGu8XlFYulnVyT0074PZvbzc");
  }
  return stripePromise;
};

export default getStripe;