import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import AddDoctor from "../../../components/public/AddDoctor/AddDoctor";
import Review from "../../../components/public/AddDoctor/Review";
import getStripe from "../../../components/public/AddDoctor/Stripe";
import Price from "../../../components/public/AddDoctor/Price";
import DoctorHeading from "../../../components/public/AddDoctor/DoctorHeading";
import DialogD from "../../../components/common/DialogD";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import PaymentSuccess from "../../../components/public/AddDoctor/PaymentSuccess";
class AddDoctorContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      npi_correct: false,
      npi_loading: false,
      npi_org_loading: false,
      npi_org_correct: false,
      npi_verified: false,
      npi_msg: "",
      customer: null,
      customer_id: "",
      subscriptionData: null,
      step: 1,
      data: null,
      npiData: [],
      address: [],
      newOrgData: [],
      orgId: null,
      organization_type: "",
      organization_id: 3,
      paymentDialog: false,
      stripeurl: "",
      orgverify: false,
      finalorgid: "",
      payment_session_id: "",
      priceId: "",
      npiOrgData: [],
    };
  }

  componentDidMount() {
    this.props.getOrgList();
    this.props.STRIPE_DOCTOR_PRICE();
    this.getParameterByName("session_id");
    this.props.queryCommonList({ parent: "gender" });
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    this.setState({
      payment_session_id: decodeURIComponent(results[2].replace(/\+/g, " ")),
    });
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.payment_session_id !== prevState.payment_session_id) {
      this.props.STRIPE_DOCTOR_VERIFY({
        id: this.state.payment_session_id,
      });
      this.setState({ step: 4 });
    }
  }

  handleSubmit = async (payload) => {
    if (payload.organization_type === "Add" && this.state.orgId === null) {
      this.props.setSnackBar({
        open: true,
        message: "Please Add Organization",
        severity: "error",
      });
    } else {
      this.setState({
        step: 2,
        data: payload,
        organization_type: payload.organization_type,
        organization_id: payload.organization_id,
      });
    }
  };

  goBackToStep1 = () => {
    this.setState({ step: 1 });
  };

  handlefinalSubmit = async () => {
    const organization_type = this.state.organization_type;

    let payload = {
      FirstName: this.state.data.FirstName,
      LastName: this.state.data.LastName,
      Email: this.state.data.Email,
      Phone: this.state.data.Phone,
      country_code: this.state.data.country_code,
      Address1: this.state.address.address_1
        ? this.state.address.address_1
        : this.state.data.Address1,
      Address2: this.state.address.address_2
        ? this.state.address.address_2
        : this.state.data.Address2,
      State: this.state.address.state
        ? this.state.address.state
        : this.state.data.State,
      City: this.state.address.city
        ? this.state.address.city
        : this.state.data.City,
      Sex: this.state.data.Sex,
      Zipcode: this.state.address.postal_code
        ? this.state.address.postal_code
        : this.state.data.Zipcode,
      organization_id:
        organization_type === "individual"
          ? 4
          : organization_type === "Add"
          ? this.state.orgId
          : this.state.finalorgid,
      npi_no: this.state.data.npi_no,
      type: this.state.data.type,
      organization_type: organization_type,
    };

    let res = await this.props.addDoctors(payload);

    if (!res.error) {
      this.setState({
        step: 4,
      });

      this.handleCheckout();
    }
  };

  setsetSubscriptionData = (data) => {
    this.setState({ subscriptionData: data });
  };

  handleVerifyOrg = async (id) => {
    let payload = {
      id: id,
    };
    let res = await this.props.verifyOrg(payload); //done

    const verify = res?.payload?.data?.verified;
    const idu = res?.payload?.data?.id;

    setTimeout(() => {
      this.setState({ orgverify: verify, finalorgid: idu });
    }, 3000);
  };

  orgSubmit = async (name, domain) => {
    let payload = {
      name: name,
      organization_domain: domain,
    };
    let res = await this.props.postOrgList(payload); //done

    this.props.getOrgList();

    setTimeout(() => {
      this.setState({
        newOrgData: this.props.post_org_data,
        orgId: this.props.post_org_data.id,
        orgverify: true,
      });
    }, 3000);
  };

  checkNpi = async (npi, first_name, last_name) => {
    this.props.checkNPI(npi);
    this.setState({ npi_loading: true });

    setTimeout(() => {
      if (
        this.props.get_npi_data[0]?.basic?.first_name.toLowerCase() ===
          first_name.toLowerCase() &&
        this.props.get_npi_data[0]?.basic?.last_name.toLowerCase() ===
          last_name.toLowerCase()
      ) {
        this.setState({
          npi_verified: true,
          npi_msg: "NPI Verified Successfully",
          npi_correct: true,
          npi_loading: false,
          npiData: this.props.get_npi_data[0],
          address: this.props.get_npi_data[0]?.addresses[0],
        });

        return true;
      } else {
        this.setState({
          npi_verified: true,
          npi_msg: "NPI Not Verified",
          npi_correct: false,
          npi_loading: false,
        });
        return false;
      }
    }, 5000);
  };

  orgNpiSubmit = async (npi, name) => {
    this.props.checkNPI(npi);
    this.setState({ npi_org_loading: true });

    setTimeout(() => {
      if (
        this.props.get_npi_data[0]?.basic?.organization_name.toLowerCase() ===
        name.toLowerCase()
      ) {
        this.setState({
          npi_verified: true,
          npi_msg: "Organization Verified Successfully",
          npi_org_correct: true,
          npi_org_loading: false,
          npiOrgData: this.props.get_npi_data[0],
        });

        return true;
      } else {
        this.setState({
          npi_verified: true,
          npi_msg: "Organization Not Verified",
          npi_org_loading: false,
          npi_loading: false,
        });
        return false;
      }
    }, 5000);
  };

  handleClose = () => {
    this.setState({ npi_verified: false });
  };

  handleCheckout = async () => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: "price_1MFBz6Hmv0ACRuxYYDnYcBmI",
          quantity: 1,
        },
      ],
      mode: "subscription",
      successUrl:
        `https://partner-dev.hipaasafe.app/success` +
        `?session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `https://partner-dev.hipaasafe.app/register/doctor/cancel`,
      customerEmail: this.state.data.Email,
    });
    console.warn(error.message);
  };

  setPriceId = (id) => {
    this.setState({ priceId: id });
  };

  render() {
    const {
      speciality_list_data,
      state_list_data,
      city_list_data,
      add_doctors_loading,
      get_org_data,
      common_list_data,
    } = this.props;

    return (
      <>
        <Paper elevation={3} sx={{ margin: "20px 30px 0px 30px" }}>
          <DialogD
            message1={this.state.npi_msg}
            handleClose={this.handleClose}
            open={this.state.npi_verified}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DoctorHeading />
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <>
                  {window.location.pathname == "/success" ? (
                    <>
                   <PaymentSuccess />
                    </>
                  ) : (
                    <>
                      {this.state.step === 4 ? (
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="center"
                        >
                          <CheckCircleIcon
                            sx={{
                              color: "#00C853",
                              fontSize: 60,
                              marginBottom: "20px",
                            }}
                          />

                          <Typography
                            variant="h6"
                            sx={{
                              color: "#00C853",
                              marginBottom: "20px",
                            }}
                          >
                            Registered Successfully Redirecting to Payment
                          </Typography>
                        </Grid>
                      ) : (
                        <>
                          {this.state.step === 3 ? (
                            <>
                              <Price
                                data={this.props.stripe_doctor_price_data}
                                setPriceId={this.setPriceId}
                              />
                              <Button
                                variant="contained"
                                sx={{
                                  width: "30%",
                                  height: "50px",
                                  background: "#3F51B5",
                                }}
                                onClick={this.handleCheckout}
                              >
                                Checkout
                              </Button>
                              ;
                            </>
                          ) : (
                            <>
                              {this.state.step === 1 ? (
                                <AddDoctor
                                  loading={add_doctors_loading}
                                  addDoctors={this.handleSubmit}
                                  speciality_list={speciality_list_data}
                                  state_data={state_list_data}
                                  city_data={city_list_data}
                                  org_data={this.props.get_org_data}
                                  orgSubmit={this.orgSubmit}
                                  checkNpi={this.checkNpi}
                                  npi_correct={this.state.npi_correct}
                                  npi_org_correct={this.state.npi_org_correct}
                                  npi_loading={this.state.npi_loading}
                                  is_update={
                                    this.props.params?.ProviderId ? true : false
                                  }
                                  data={this.state.data}
                                  handleVerifyOrg={this.handleVerifyOrg}
                                  orgverify={this.state.orgverify}
                                  orgNpiSubmit={this.orgNpiSubmit}
                                  npi_org_loading={this.state.npi_org_loading}
                                  gender_data={common_list_data}
                                />
                              ) : (
                                <>
                                  <Review
                                    data={this.state.data}
                                    npiData={this.state.npiData}
                                    address={this.state.address}
                                    newOrgData={this.state.newOrgData}
                                    handlefinalSubmit={this.handlefinalSubmit}
                                    orgId={this.state.organization_id}
                                    organization_type={
                                      this.state.organization_type
                                    }
                                    goBackToStep1={this.goBackToStep1}
                                    gender_data={common_list_data}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

export default AddDoctorContainer;
