import React from "react";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import HistoryIcon from "@mui/icons-material/History";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { axios } from "../../../helpers/axios.js";
import { authenticate } from "../../../helpers/cookies";
import TermsAndConditionsDialog from "../../../components/doctor/TermsAndConditionsDialog";

import BaseLayout from "../../../components/doctor/layout/BaseLayout";
import DoctorRoutes from "../../../routes/DoctorRoutes";
import {
  isAuth,
  signout,
  updateUserTerms,
  updatePaymentStatus,
} from "../../../helpers/cookies";

import {
  cometChatInitialize,
  cometChatInitializeFCM,
  cometChatListener,
  cometChatLoginUser,
  cometChatMessageListener,
  setUnreadMessageCount,
  cometChatLogoutUser,
} from "../../../helpers/config/cometChat.js";
import { cometChatConfig } from "../../../helpers/constants.js";

import SplashScreen from "../../../components/common/SplashScreen";
import { CometChatConversationListWithMessages } from "../../../cometchat";
import { withTheme } from "@mui/styles";
import { theme } from "../../../cometchat/resources/theme";
import { generateFCMToken } from "../../../helpers/config/initializeFCM";
import {
  generateOneSignalToken,
  registerOneSignalTokenWithServer,
} from "../../../helpers/config/initializeOneSignal";
import { Badge } from "@mui/material";
import {
  disconnectSocket,
  initiateSocketConnection,
  receiveMsgHere,
} from "../../../socketio.service";
import getStripe from "../../../components/public/AddDoctor/Stripe";

import {
  doctorOrgnizationMapSwitch,
  stripeCancelUrl,
  stripeSuccessUrl,
  StripePriceId,
} from "../../../helpers/constants";

class DoctorContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      isDrawerExpanded: true,
      appInitializing: true,
      chat_window_state: false,
      isError: false,
      errorMessage: "",
      termsAndConditions: false,
      loggedInUser: null,
    };
    this.initializeApp = this.initializeApp.bind(this);
    this.socketInitialize = this.initializeSocket.bind(this);
    this.handleChatWindowState = this.handleChatWindowState.bind(this);
  }

// Initialize the app and CometChat
 initializeApp = async () => {
  try {
    
    await cometChatInitialize(cometChatConfig.appRegion, cometChatConfig.appID);
 
    const uidComet = `${await isAuth().uid}${await isAuth().org_details.unique_id}`;
    const loggedInUser = await cometChatLoginUser(uidComet, cometChatConfig.authKey);
    this.setState({ loggedInUser });

    await this.initializeCometChat();
  } catch (error) {
    console.log("error", error);
    this.setState({ isError: true, errorMessage: error.message });
  }
};

// Initialize CometChat listeners and FCM/OneSignal tokens
 initializeCometChat = async () => {
  await cometChatListener(this.props.set_chat_window_state);
  await cometChatMessageListener(this.props.set_unread_message_count, this.props.set_chat_window_state);
  await setUnreadMessageCount(this.props.set_unread_message_count);

  const fcmToken = await generateFCMToken();
  if (fcmToken) {
    await cometChatInitializeFCM();
  }

  setTimeout(() => {
    this.setState({ appInitializing: false });
  }, 300);

  const oneSignalToken = await generateOneSignalToken();
  if (oneSignalToken) {
    await registerOneSignalTokenWithServer(oneSignalToken);
  }
};

// Initialize the socket connection
 initializeSocket = () => {
  initiateSocketConnection(this.props.DoctorId);
  receiveMsgHere(this.props.dispatch);
};

// Fetch provider data by ID
 fetchProviderDataById = () => {
  if (this.props.DoctorId) {
    this.props.getDoctorDataUrl({ uid: this.props.DoctorId });
  }
};

// Handle drawer toggle
 handleDrawerToggle = (isDrawerExpanded) => {
  this.setState({ isDrawerExpanded });
};

// Handle sidebar redirection
 handleSidebarRedirection = (pathname) => {
  if (window.innerWidth < 900) {
    this.handleDrawerToggle(false);
  }
  this.props.history.push(pathname);
};

// Component did mount
componentDidMount() {
  this.Notification();
    this.initializeApp();
    this.socketInitialize();
    this.props.doctorOrgnization();

    this.fetchProviderDataById();


  const { payment_status, speciality_id, role_name } = isAuth();

  if (!payment_status) {
    this.handleCheckout();
    //logout
    this.props.navigate("/logout");

    updatePaymentStatus(true);

    // refresh the page

    return;
  }

  if (!this.props.termsAndConditions) {
    this.setState({ termsAndConditions: true });
  }

  setTimeout(() => {
    if (
      speciality_id === null &&
      role_name === "DOCTOR" &&
      window.location.pathname !== "/logout"
    ) {
      this.props.navigate("/profile-update/" + this.props.DoctorId);
    }
  }, 1000);
}


  componentDidUpdate(prevProps) {
    if (prevProps.DoctorId !== this.props.DoctorId) {
      disconnectSocket();
      this.socketInitialize();
    }
  }

  Notification = () => {
    Notification.requestPermission().then(function (result) {});
  };

   handleCheckout = async () => {
    // Get the Stripe object
    const stripe = await getStripe();
  
    // Redirect to checkout with the specified line items and options
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: StripePriceId,
          quantity: 1,
        },
      ],
      mode: "subscription",
      successUrl: `https://partner-dev.hipaasafe.app/success?session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `https://partner-dev.hipaasafe.app/logout`,
      customerEmail: isAuth().Email,
    });
  
    // Log any errors to the console
    console.warn(error.message);
  };
  handleChatWindowState() {
    setUnreadMessageCount(this.props.set_unread_message_count);
    this.props.set_chat_window_state();
  }

  handleAcceptTermsAndConditions = () => {
    this.setState({ termsAndConditions: false });
    this.props.AcceptTermsAndConditions();

    updateUserTerms(true);
  };

  handleSignOut = async () => {
    await this.props.userLogout();
    signout(() => window.location.reload());
  };
 
  handleOrganizationSwitch = async (data) => {
    try {
      const payload = {
        organization_id: data,
      };
      const login_response = await axios.put(
        doctorOrgnizationMapSwitch,
        payload
      );
      if (login_response.success) {
        // console.log("login_response", login_response);
        authenticate(login_response.data, () => window.location.reload());
        cometChatLogoutUser();
        const loggedInUser = await cometChatLoginUser(
          login_response.data.ProviderId +
            login_response.data.org_details.unique_id,
          cometChatConfig.authKey
        );
        // console.log("loggedInUser", loggedInUser);
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { appInitializing, isError, errorMessage, isDrawerExpanded } =
      this.state;

    const {
      chat_window_state,
      chat_window_uid,
      chat_window_guid,
      unread_message_count,
      doctor_orgnization_data,
    } = this.props;

    if (appInitializing)
    return <SplashScreen isError={isError} errorMessage={errorMessage} />;

    let conversationsComponent = null;
    if (this.state.loggedInUser) {
      conversationsComponent = (
        <CometChatConversationListWithMessages
          chat_window_state={chat_window_state}
          theme={
            //  this.props.theme.palette.mode === "dark" ? darkTheme : theme
            theme
          }
          chatWithUser={chat_window_uid}
          chatWithGroup={chat_window_guid}
          setChatWindowUid={(d) => {
            this.props.set_chat_window_ids(d);
          }}
        />
      );
    }

    return (
      <Stack>
        <Stack direction="row">
          <Stack sx={{ position: "fixed", bottom: 18, right: 18, zIndex: 1 }}>
            <Fab
              onClick={this.handleChatWindowState}
              color="primary"
              aria-label="add"
              width="100%"
              height="100%"
              background="none"
            >
              {chat_window_state ? (
                <CloseIcon />
              ) : (
                <Badge
                  color="primary"
                  badgeContent={unread_message_count}
                  componentsProps={{
                    badge: {
                      sx: {
                        top: 20,
                        right: 40,
                        fontSize: 18,
                        width: 25,
                        height: 25,
                        borderRadius: 20,
                      },
                    },
                  }}
                >
                  <img src="/grey-chats.svg" alt="Chat" />
                </Badge>
              )}
            </Fab>
          </Stack>
          <Stack
            zIndex={chat_window_state ? 99999999 : -1000000}
            sx={{
              height: "80vh",
              width: "40%",
              position: "fixed",
              bottom: 80,
              right: 18,
              color: `${theme.color.primary}`,
            }}
          >
            {conversationsComponent}
          </Stack>
        </Stack>

        <TermsAndConditionsDialog
          open={this.state.termsAndConditions}
          onClose={this.handleSignOut}
          onAgree={this.handleAcceptTermsAndConditions}
        />

        <BaseLayout
          params={this.props.params}
          isDrawerExpanded={isDrawerExpanded}
          modules={doctorModules}
          ChildComponent={DoctorRoutes}
          doctor_orgnization_data={doctor_orgnization_data}
          handleOrganizationSwitch={this.handleOrganizationSwitch}
          onDrawerToggle={this.handleDrawerToggle}
          userLogout={this.props.userLogout}
        />
      </Stack>
    );
  }
}

export default withTheme(DoctorContainer);

const doctorModules = [
  {
    id: "dashboard",
    title: "Dashboard",
    logo: <HomeIcon />,
    link: "/dashboard",
  },
  {
    id: "schedule",
    title: "Appointments",
    logo: <CalendarTodayIcon />,
    link: "/schedule",
  },
  {
    id: "history",
    title: "History",
    logo: <HistoryIcon />,
    link: "/history",
  },
  {
    id: "chat",
    title: "Connections",
    logo: <img src="/connections.svg" alt="Connections" />,
    link: "/chat",
  },
  {
    id: "qr code",
    title: "QR Code",
    logo: <QrCodeIcon />,
    link: "/qr-code",
  },
  {
    id: "profile",
    title: "My Profile",
    logo: <PermIdentityOutlinedIcon />,
    link: "/profile",
  },
];
